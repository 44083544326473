import React, { useEffect, useState, useRef } from "react";
import { format } from "date-fns";

interface SystemInfoListItemProps {
  title: string;
  startDate: Date;
  memo: string;
}

const SystemInfoListItem: React.FC<SystemInfoListItemProps> = ({
  title,
  startDate,
  memo,
}) => {
  const parentsRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  const getContentHeight = () => {
    if (!parentsRef.current) return;
    const content = document.createElement("div");
    content.classList.add("p-2", "text-lg");
    const text = document.createElement("pre");
    text.innerText = memo;
    content.appendChild(text);
    parentsRef.current.append(content);
    setContentHeight(content.offsetHeight);
    parentsRef.current.removeChild(content);
  };
  useEffect(() => {
    getContentHeight();
  }, [parentsRef.current]);

  return (
    <div
      ref={parentsRef}
      className="w-full cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="flex justify-between items-center p-2 border-b border-b-[#f3f3f3]">
        <div className="w-full lg:flex text-lg lg:space-x-3">
          <p className="w-[10%] text-primary">
            {format(new Date(startDate), "yyyy.MM.dd")}
          </p>
          <p className="flex-1 font-semibold text-xl">{title}</p>
        </div>
        <button
          className={`w-10 h-10 bg-[#f3f3f3] p-2 rounded-full text-primary font-bold ${
            isOpen ? "-rotate-45" : "rotate-0"
          } transition-transform ease-linear duration-200`}
        >
          +
        </button>
      </div>
      <div
        className={`bg-[#fdf1f3] text-lg text-[#707070] ${
          isOpen ? "p-2" : "p-0"
        } transition-[height] ease-linear duration-200`}
        style={{ height: isOpen ? contentHeight : 0 }}
      >
        <pre
          className={`font-sans transition-opacity ease-linear duration-300 ${
            isOpen
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          {memo}
        </pre>
      </div>
    </div>
  );
};

export default SystemInfoListItem;
