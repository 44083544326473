export const BASE_PATH = "/";
export const LOGIN_PATH = "/login";
export const UPLOAD_PATH = "/upload";
export const UNPUBLISHED_PICTURES_PATH = "/publish";
export const DELETE_PICTURES_PATH = "/delete";
export const PICTURES_RESULTS_PATH = "/pictures/results";
export const PICTURES_PATH = "/pictures";
export const PICTURES_SUMMARIZATION_PATH = "/pictures/summarization";
export const CLASS_PATH = "/class";
export const STUDENT_PATH = "/class/student";
export const ACCOUNT_PATH = "/account";
export const DELETE_ACCOUNT_PATH = "/account/delete";
export const FACILITY_PATH = "/facility";
export const SYSTEM_INFO_PATH = "/system";
export const SETTING_PATH = "/setting";
export const CHANGE_PASSWORD_PATH = "/account/change_password";
